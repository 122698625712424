export enum ArticlePublishersName {
    PUBLIC = 'FrenchFounders public',
    LES_TALENTS = 'LesTalents',
    NETWORK = 'Network',
    CLUB = 'Club',
    IC = 'IC',
    GROUP_CFO_BPI = 'Cercle CFO Bpifrance'
}

export enum ResourceType {
    ARTICLE = 'article',
    VIDEO = 'video',
    PODCAST = 'podcast',
    FOLDER = 'folder'
}
