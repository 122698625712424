<script setup lang="ts">
    import BaseTag from '@/components/ui/basics/BaseTag.vue'
    import BaseButton from '@/components/ui/basics/BaseButton.vue'
    import BaseHr from '@/components/ui/basics/BaseHr.vue'
    import BaseIcon from '@/components/ui/basics/BaseIcon.vue'
    import AvatarMedia from '@/components/ui/medias/AvatarMedia.vue'
    import CardWrapper from '@/components/ui/wrappers/cards/CardWrapper.vue'
    import GutterWrapper from '@/components/ui/wrappers/GutterWrapper.vue'
    import { useAuthStore } from '@/libs/stores/auth.store'
    import { ButtonTheme } from '@/vars/ButtonAttr'
    import { RouteName } from '@/vars/RouteName'
    import { ref, computed } from 'vue'
    import { useI18n } from 'vue-i18n'
    import { ConfigurationItem, ExperienceYears, ExperienceIndustries } from '@/types/Job.type'
    import AccordionNavigation from '@/components/ui/navigations/AccordionNavigation.vue'
    import { useTheme } from '@/libs/compositions/useTheme'
    import { ThemeName } from '@/vars/ThemeAttr'
    import abstractPattern from '@/assets/patterns/abstract.svg'

    const props = defineProps<{
        profile: {
            anonymous?: boolean
            title?: string
            searchStatus?: string
            experienceYears?: ExperienceYears
            skills?: string[]
            search?: {
                contractType?: string
                positions?: string[]
                industriesLabels?: ExperienceIndustries
                cities?: string[]
                remote?: string
            }
        }
        config?: {
            candidateSearchStatus: ConfigurationItem[]
            contractType: ConfigurationItem[]
            experienceYears: ConfigurationItem[]
            remote: ConfigurationItem[]
        }
    }>()

    const store = useAuthStore()
    const { t } = useI18n()
    const { theme } = useTheme()

    const isComplete = computed(() => {
        return props.profile.search
    })

    const isSearchExpanded = ref(false)

    const showDetailsSearch = computed(() => {
        return (
            (props.profile.search?.positions && props.profile.search?.positions.length > 0) ||
            props.profile.search?.contractType ||
            (props.profile.search?.industriesLabels && props.profile.search?.industriesLabels.length > 0) ||
            (props.profile.search?.cities && props.profile.search?.cities.length > 0) ||
            props.profile.search?.remote
        )
    })
</script>

<template>
    <CardWrapper
        class="profile"
        :class="{
            'profile--mondrian': theme === ThemeName.MONDRIAN
        }"
    >
        <div v-if="theme === ThemeName.MONDRIAN" class="profile__header">
            <img :src="abstractPattern" />
        </div>
        <div
            class="profile__inner profile__inner--large"
            :class="{
                'profile__inner--mondrian': theme === ThemeName.MONDRIAN
            }"
        >
            <CardWrapper class="profile__member">
                <AvatarMedia :src="store.authentifiedUser?.picture" alt="" class="profile__avatar" />
                <div
                    class="profile__name"
                    :class="{
                        'profile__name--marges':
                            profile.title ||
                            store.authentifiedUser?.company ||
                            store.authentifiedUser?.position ||
                            profile.experienceYears ||
                            (profile.skills && profile.skills.length > 0)
                    }"
                >
                    {{ store.authentifiedUser?.first_name }}
                    {{ store.authentifiedUser?.last_name }}
                </div>
                <div
                    class="profile__position"
                    :class="{
                        'profile__position--marges':
                            profile.experienceYears || (profile.skills && profile.skills.length > 0)
                    }"
                >
                    <p v-if="profile.title">{{ profile.title }}</p>
                    <div v-if="store.authentifiedUser?.company || store.authentifiedUser?.position">
                        <template v-if="store.authentifiedUser.position">
                            {{ store.authentifiedUser.position }}
                        </template>
                        <template v-if="store.authentifiedUser.company && store.authentifiedUser.company.name">
                            @{{ store.authentifiedUser?.company.name }}
                        </template>
                    </div>
                </div>
                <div
                    v-if="
                        profile.experienceYears && config?.experienceYears.find((c) => c.id === profile.experienceYears)
                    "
                    class="profile__experience"
                    :class="{
                        'profile__position--marges': profile.skills && profile.skills.length > 0
                    }"
                >
                    <BaseIcon legacy name="briefcase-1" class="profile__icon" />
                    {{ config?.experienceYears.find((c) => c.id === profile.experienceYears)?.name }}
                </div>
                <GutterWrapper
                    v-if="profile.skills && profile.skills.length > 0"
                    space="0.65"
                    class="profile__tag-list"
                >
                    <BaseTag v-for="skill in profile.skills" :key="skill" small class="profile__badge">
                        {{ skill }}
                    </BaseTag>
                </GutterWrapper>
            </CardWrapper>
        </div>
        <div class="profile__inner">
            <div class="profile__row profile__row--center">
                <BaseButton
                    :to="{ name: RouteName.TALENT_PROFILE }"
                    :theme="isComplete ? ButtonTheme.SIMPLE : ButtonTheme.PRIMARY"
                    :reverse="isComplete"
                >
                    {{ t(isComplete ? 'update_profile' : 'complete_profile') }}
                </BaseButton>
            </div>
        </div>
        <template v-if="showDetailsSearch">
            <BaseHr />
            <div class="profile__inner profile__search">
                <div class="profile__row">
                    <AccordionNavigation
                        class="profile__accordion"
                        :open-by-default="isSearchExpanded"
                        @open="isSearchExpanded = true"
                        @close="isSearchExpanded = false"
                    >
                        <template #header>
                            <div class="profile__title">
                                {{ t('search') }}
                            </div>
                        </template>
                        <template #default>
                            <div class="profile__row">
                                <div
                                    v-if="profile.search?.positions && profile.search.positions.length > 0"
                                    class="profile__value"
                                >
                                    {{ t('position') }} :
                                    {{ profile.search.positions.join(', ') }}
                                </div>
                                <div
                                    v-if="
                                        profile.search?.contractType &&
                                        config?.contractType.find((c) => c.id === profile.search?.contractType)
                                    "
                                    class="profile__value"
                                >
                                    {{ t('position_type') }} :
                                    {{ config?.contractType.find((c) => c.id === profile.search?.contractType)?.name }}
                                </div>
                            </div>
                            <GutterWrapper
                                v-if="profile.search?.industriesLabels && profile.search?.industriesLabels.length > 0"
                                space="0.65"
                                class="profile__row"
                            >
                                <BaseTag
                                    v-for="industry in profile.search?.industriesLabels"
                                    :key="industry.id"
                                    small
                                    class="profile__badge"
                                >
                                    {{ industry.name }}
                                </BaseTag>
                            </GutterWrapper>
                            <div class="profile__row">
                                <div
                                    v-if="profile.search?.cities && profile.search?.cities.length > 0"
                                    class="profile__value"
                                >
                                    <BaseIcon legacy name="marker" class="profile__icon" />
                                    {{ profile.search?.cities.join(', ') }}
                                </div>
                                <div
                                    v-if="
                                        profile.search?.remote &&
                                        config?.remote.find((c) => c.id === profile.search?.remote)
                                    "
                                    class="profile__value"
                                >
                                    {{ t('remote') }} :
                                    {{ config?.remote.find((c) => c.id === profile.search?.remote)?.name }}
                                </div>
                            </div>
                        </template>
                    </AccordionNavigation>
                </div>
            </div>
        </template>
    </CardWrapper>
</template>

<style scoped lang="scss">
    @import '@/libs/sass/vars';
    .profile {
        $block-selector: &;

        width: 100%;
        padding-top: 5.625em;
        background-image: url('@/assets/images/jobs/card_bg.png');
        background-size: 100% auto;
        background-repeat: no-repeat;
        background-position: top;
        margin: 0 1em 1.25em;
        max-width: 32em;

        @media screen and (min-width: $breakpoints-xl) {
            max-width: 18.75em;
        }

        &--mondrian {
            background-image: none;
            padding-top: var(--scale-20);
            position: relative;
        }

        &__header {
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            background-color: var(--theme-color-legacy);
            padding-inline: var(--scale-4);
            padding-bottom: var(--scale-10);
            border-radius: 0.5em;
            border-bottom-left-radius: 15%;
            border-bottom-right-radius: 15%;
            display: flex;
            justify-content: center;
            z-index: 1;
        }

        @media (min-width: $breakpoints-lg) {
            margin: 0 0 1.25em;
        }

        &__inner {
            padding: 0 2em;

            &--large {
                padding: 0 1.125em;
            }
            &--mondrian {
                z-index: 2;
                position: relative;
            }
        }

        &__member {
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
            padding: 0 1.25em 1.25em;
            margin-bottom: 1.5em;
            min-height: 5.75em;
        }

        &__avatar {
            border: 2px solid var(--theme-background-color);
            transform: translateY(-50%);
            margin-bottom: -2em;
        }

        &__name {
            font-weight: 700;

            &--marges {
                margin-bottom: 0.25em;
            }
        }

        &__position,
        &__experience {
            font-weight: 600;
            font-size: 0.875em;
            color: var(--theme-text-color--secondary);

            &--marges {
                margin-bottom: 1em;
            }
        }

        &__tag-list {
            justify-content: center;
        }

        &__title {
            font-weight: 700;
            margin-bottom: 1em;
            font-size: 1em;

            &--label {
                font-weight: 700;
            }

            #{$block-selector}__accordion & {
                margin-bottom: 0;
            }
        }

        &__icon {
            margin-right: 0.25em;
        }

        &__row {
            margin-bottom: 1.5em;

            &--center {
                text-align: center;
            }
        }

        &__search {
            overflow: auto;
        }

        &__accordion {
            :deep(.accordion__body) {
                margin-top: 1em;
            }
        }

        &__value {
            font-size: 0.875em;
            color: var(--theme-text-color--secondary);
        }

        :deep(.label) {
            font-size: 0.875em;
            font-weight: 500;
        }
    }
</style>

<i18n lang="json">
{
    "fr": {
        "search": "Ma recherche",
        "position": "Poste recherché",
        "position_type": "Type de poste",
        "remote": "Télétravail",
        "status_title": "Statut de mon profil",
        "update_profile": "Gérer ma recherche",
        "complete_profile": "Rejoindre le talent pool",
        "profile_description": "Vous souhaitez recevoir des offres d’emploi personnalisées ?",
        "search_empty": "Vous n'avez pas encore renseigné votre recherche",
        "notif": {
            "title": "Votre statut a bien été mis à jour"
        }
    }
}
</i18n>
