<script setup lang="ts">
    import BaseLink from '@/components/ui/actions/BaseLink.vue'
    import { LinkTheme } from '@/vars/LinkAttr'
    import { useI18n } from 'vue-i18n'
    import SpaRouterLink from '@/components/ui/navigations/SpaRouterLink.vue'
    import TLFIllustration from './images/TLF.svg'
    import External from './images/external.png'
    import ExternalMobile from './images/external_mobile.png'
    import { RouteName } from '@/vars/RouteName'
    import useBreakpoints from '@/libs/compositions/useBreakpoints'

    const { t } = useI18n()
    const { breakpoints } = useBreakpoints()

    const cards = [
        {
            key: 'tlf',
            title: t('title.tlf'),
            image: TLFIllustration,
            href: 'https://tlf.frenchfounders.com/',
            action: t('know_more')
        },
        {
            key: 'external',
            title: t('title.external'),
            image: External,
            imageMobile: ExternalMobile,
            to: { name: RouteName.EVENT_LIST_EXTERNAL },
            action: t('know_more')
        }
    ]
</script>

<template>
    <div class="md:flex md:gap-4">
        <SpaRouterLink
            v-for="card in cards"
            :key="card.key"
            :to="card?.to"
            :href="card?.href"
            class="card mt-4 flex min-h-full flex-1 gap-2 overflow-hidden rounded-lg bg-legacy md:mt-0 md:flex-col md:gap-0"
        >
            <div
                class="card__image flex min-h-[130px] max-w-[126px] items-center justify-center px-4 py-2 align-middle md:max-w-full"
            >
                <img :src="breakpoints.xs && card.imageMobile ? card.imageMobile : card.image" class="object-contain" />
            </div>

            <div class="mt-4 flex flex-1 flex-col justify-between px-4 pb-4 pt-2 text-nuage md:text-center">
                <div class="flex-1 font-medium md:text-sm">{{ card.title }}</div>
                <div class="mt-4 flex translate-x-2 justify-end text-xs md:justify-center">
                    <BaseLink :theme="LinkTheme.IMPORTANT_LIGHT">
                        {{ card.action }}
                    </BaseLink>
                </div>
            </div>
        </SpaRouterLink>
    </div>
</template>

<style scoped lang="scss">
    .card {
        &__image {
            background-image: url('@/assets/patterns/f-grid-legacy-royale.svg');
            background-size: cover;
        }
    }
</style>

<i18n lang="json">
{
    "fr": {
        "title": { "tlf": "Transatlantic Leaders Forum", "external": "Autres rencontres extérieures" },
        "know_more": "En savoir plus"
    },
    "en": {
        "title": { "tlf": "Transatlantic Leaders Forum", "external": "Other external meetings" },
        "know_more": "Learn more"
    }
}
</i18n>
