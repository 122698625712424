<script setup lang="ts">
    import { computed } from 'vue'
    import { useField } from 'vee-validate'
    import { v4 as uuid } from 'uuid'

    const props = withDefaults(
        defineProps<{
            name?: string
            rules?: string
            modelValue?: boolean
            reverse?: boolean
            indeterminate?: boolean
            disabled?: boolean
            syncVModel?: boolean
            center?: boolean
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            checkedValue?: any
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            uncheckedValue?: any
            testId?: string
        }>(),
        {
            rules: '',
            syncVModel: undefined, // On force volontairement
            center: undefined,
            checkedValue: true,
            uncheckedValue: false
        }
    )

    const emit = defineEmits<{
        (e: 'update:modelValue', value: boolean): void // Triggered by "useField"
        (e: 'check', value: boolean): void
    }>()

    computed(() => {
        if (!props.rules) return false
        if (typeof props.rules === 'string') {
            return props.rules.includes('required')
        }
        return Object.keys(props.rules).includes('required')
    })

    const { checked, handleChange } = useField(props.name || uuid(), props.rules, {
        type: 'checkbox',
        checkedValue: props.checkedValue, // Which value to set when it's checked
        uncheckedValue: props.uncheckedValue, // Which value to set when it's unchecked
        initialValue: props.name ? undefined : props.modelValue || false,
        standalone: !props.name,
        syncVModel: props.syncVModel ?? true
    })

    const onToggle = () => {
        const toChecked = !checked?.value

        if (props.disabled) {
            return null
        }

        handleChange(toChecked)
        emit('check', toChecked)
    }
</script>

<template>
    <div class="input">
        <input
            class="input__origin"
            type="checkbox"
            :checked="checked"
            :indeterminate="indeterminate"
            :disabled="disabled"
        />
        <label
            class="input__line"
            :class="{ 'input__line--reverse': reverse, 'input__line--center': center }"
            :data-testId="testId"
            @click="onToggle"
        >
            <span
                class="input__checkbox"
                :class="{
                    'input__checkbox--active': checked || indeterminate,
                    'input__checkbox--disabled': disabled
                }"
            >
                <span v-if="indeterminate" class="input__indeterminate"></span>
                <span v-else class="input__check"></span>
            </span>
            <span class="input__text">
                <slot></slot>
            </span>
        </label>
    </div>
</template>

<style scoped lang="scss">
    .input {
        $block: &;
        display: flex;
        flex-direction: column;

        &__origin {
            display: none;
        }

        &__line {
            display: inline-flex;
            align-items: center;
            grid-gap: var(--scale-2);
            cursor: pointer;

            &--center {
                align-items: center;
            }

            &--reverse {
                flex-direction: row-reverse;
            }
        }

        &__checkbox {
            $el: &;
            --size: 1.25rem;

            width: var(--size);
            min-width: var(--size);
            height: var(--size);
            background: #ccc;
            border-radius: 4px;
            position: relative;
            transition: 0.2s ease;
            transition-delay: 0.4s;

            &::before {
                content: '';
                position: absolute;
                top: 2px;
                left: 2px;
                right: 2px;
                bottom: 2px;
                background: #fff;
                border-radius: 2.5px;
                transition: 0.2s ease;
                transition-delay: 0.2s;
            }

            &--disabled {
                cursor: not-allowed;
                color: var(--theme-color-gray--04);
                background: currentColor;

                &::before {
                    background: var(--theme-color-gray--06);
                }
            }

            &--active#{$el}--disabled {
                background: var(--theme-color-gray--06);
                color: var(--theme-color-gray--04) !important;
            }

            &--active {
                color: var(--theme-primary-text-color);
                transition-delay: 0s;
                background: var(--theme-text-color);

                &:before {
                    top: 10px;
                    left: 10px;
                    right: 10px;
                    bottom: 10px;
                    border-radius: 50%;
                    transition-delay: 0s;
                }
            }
        }

        &__text {
            display: inline-flex;
            align-items: center;
            margin-top: calc(var(--scale-px) * -1); // Pour contrer le léger décallage d'alignement
        }

        &__check {
            display: block;
            width: 12px;
            height: 6px;
            position: absolute;
            top: 6px;
            left: 50%;
            transform: translateX(-50%) rotateZ(-45deg);

            #{$block}__checkbox--active::before & {
                height: 100%;
                transition-delay: 0.2s;
            }
            #{$block}__checkbox--active::after & {
                width: 100%;
                transition-delay: 0.4s;
            }

            &::before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 2px;
                height: 0;
                background: currentColor;
                transition: height 0.2s ease;
                transition-delay: 0.2s;

                #{$block}__checkbox--active & {
                    height: 100%;
                    transition-delay: 0.2s;
                }
            }

            &::after {
                content: '';
                position: absolute;
                bottom: 0;
                left: 0;
                width: 0;
                height: 2px;
                background: currentColor;
                transition: width 0.2s ease;
                transition-delay: 0s;

                #{$block}__checkbox--active & {
                    width: 100%;
                    transition-delay: 0.3s;
                }
            }
        }

        &__indeterminate {
            display: block;
            width: 12px;
            height: 6px;
            position: absolute;
            top: 50%;
            left: 50%;

            #{$block}__checkbox--active::before & {
                height: 100%;
                transition-delay: 0.2s;
            }
            #{$block}__checkbox--active::after & {
                width: 100%;
                transition-delay: 0.4s;
            }

            &::before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 0.5rem;
                height: 0.2rem;
                background: currentColor;
                transition: 0.2s ease;
                transition-delay: 0.2s;
                transform: translate(-50%, -50%);

                #{$block}__checkbox--active & {
                    width: 100%;
                    transition-delay: 0.2s;
                }
            }
        }
    }
</style>
