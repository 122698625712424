<script setup lang="ts">
    import { ref } from 'vue'
    import { useI18n } from 'vue-i18n'
    import useMenuNavigationState from '@/components/layouts/common/header/navigations/profile/partials/useMenuNavigationState'
    import DropdownNavigation from '@/components/ui/navigations/dropdowns/DropdownNavigation.vue'
    import DropdownContent from '@/components/ui/navigations/dropdowns/DropdownContent.vue'
    import DropdownItem from '@/components/ui/navigations/dropdowns/DropdownItem.vue'
    import BaseIcon from '@/components/ui/basics/BaseIcon.vue'
    import BaseTag from '@/components/ui/basics/BaseTag.vue'
    import { useAuthStore } from '@/libs/stores/auth.store'
    import { HeaderCity } from '@/types/Header.type'
    import { SpaName } from '@/vars/SpaAttr'
    import { TagType } from '@/vars/TagAttr'
    import { AboutRouteName } from '@/vars/RouteName'
    import SpaRouterLink from '@/components/ui/navigations/SpaRouterLink.vue'

    const spaRunning = import.meta.env.VITE_SPA_RUNNING
    const store = useAuthStore()
    const { t } = useI18n()
    const { isOpened } = useMenuNavigationState()
    const selectedCity = ref<string>('New York')

    const cities: HeaderCity[] = [
        { name: 'New York', available: true },
        { name: 'San Francisco', available: false }
    ]

    const selectCity = (city: HeaderCity): void => {
        if (city.available && city.name !== selectedCity.value) {
            selectedCity.value = city.name
        }
    }
</script>

<template>
    <DropdownNavigation show-on-click class="city" @open="isOpened = true" @close="isOpened = false">
        <template #activator>
            <div
                class="city__pill"
                :class="{
                    'city__pill--dark': spaRunning === SpaName.LECLUB,
                    'city__pill--network': spaRunning === SpaName.LENETWORK
                }"
            >
                <BaseIcon name="public" class="city__icon mr-1" />
                {{ selectedCity }}
            </div>
        </template>
        <template #dropdown>
            <DropdownContent v-if="store.isLoggedIn" class="city__dropdown" align="left">
                <section class="section">
                    <div class="section__head">
                        <h5 class="section__title">{{ t('switch') }}</h5>
                    </div>
                    <div class="section__items">
                        <DropdownItem
                            v-for="city in cities"
                            :key="city"
                            class="section__item"
                            :class="{
                                'section__item--disabled': !city.available
                            }"
                            :hoverable="false"
                            @click="selectCity(city)"
                        >
                            <div class="flex items-center justify-between">
                                <span class="section__city">{{ city.name }}</span>
                                <BaseTag v-if="city.name === selectedCity" class="section__tag">
                                    {{ t('you_are_here') }}
                                </BaseTag>
                                <BaseTag v-else-if="!city.available" :type="TagType.NEUTRAL" class="section__tag">
                                    {{ t('coming_soon') }}
                                </BaseTag>
                            </div>
                        </DropdownItem>
                    </div>
                </section>
                <section class="section">
                    <div class="section__head">
                        <SpaRouterLink
                            class="section__link"
                            :to="{
                                name: AboutRouteName.WEWEB_VOTE_NEXT_CITY,
                                query: {
                                    firstName: store.authentifiedUser?.first_name,
                                    lastName: store.authentifiedUser?.last_name,
                                    email: store.authentifiedUser?.email
                                }
                            }"
                            data-testid="new-group-id"
                        >
                            <h5 class="section__title">{{ t('vote') }}</h5>
                            <small class="section__subtitle">
                                {{ t('vote_subtitle') }}
                            </small>
                        </SpaRouterLink>
                    </div>
                </section>
            </DropdownContent>
        </template>
    </DropdownNavigation>
</template>

<style scoped lang="scss">
    @import '@/libs/sass/vars';

    .city {
        $block-selector: &;
        height: 100%;
        display: flex;
        position: relative;

        &__pill {
            display: flex;
            align-items: center;
            font-size: 0.875rem;

            &--network {
                @media screen and (max-width: $breakpoints-lg) {
                    padding: 0 !important;
                    border: none !important;
                }
            }

            @media only screen and (min-width: $breakpoints-lg) {
                padding: 0.375em 0.875em;
                border-radius: (0.5em * 2) + (0.375em * 2);
                background-color: var(--theme-background-color--header-pill);
                border: 1px solid var(--theme-border-color--header-pill);

                &--dark {
                    background-color: rgba(242, 242, 242, 0.1) !important;
                    border: transparent;
                }
            }
        }

        &__icon {
            height: 2rem;
            font-size: 1.25rem;
            * {
                fill: var(--theme-text-color--header);
            }
        }

        &__dropdown {
            width: 18em;
        }
    }

    .section {
        $block-selector: &;
        margin: 0.5rem 0 1em 0;

        &:last-child {
            margin: 0;
        }

        &__head {
            display: flex;
            align-items: center;
            padding: 0 1.5rem;
            margin-bottom: 0.5rem;
        }

        &__icon {
            font-size: 1.5rem;
        }

        &__link {
            display: block;
            padding-bottom: 0.5rem;
        }
        &__title {
            font-size: 1em;
            font-weight: 600;
            margin: 0;
            padding: 0;
            white-space: nowrap;

            #{$block-selector}__link & {
                padding-bottom: 0;
            }
        }
        &__subtitle {
            font-size: 0.75em;
        }

        &__city {
            padding: 0.125rem 0;
        }

        &__item--disabled {
            color: var(--theme-color-gray--04);
        }

        &__tag {
            #{$block-selector}__item--disabled & {
                display: none;
            }

            #{$block-selector}__item--disabled:hover & {
                display: block;
            }
        }
    }
</style>

<i18n lang="json">
{
    "fr": {
        "switch": "Changer de ville",
        "vote": "Voter pour la prochaine ville",
        "vote_subtitle": "Quelle ville devons-nous lancer ensuite ? Venez voter !",
        "you_are_here": "Vous êtes ici",
        "coming_soon": "Coming soon"
    },
    "en": {
        "switch": "Choose a city",
        "vote": "Vote for the next city",
        "vote_subtitle": "Which city should we launch next? Come and vote!",
        "you_are_here": "You are here",
        "coming_soon": "Coming soon"
    }
}
</i18n>
