<script setup lang="ts">
    import { computed, ref, watch } from 'vue'
    import { isTouchDevice } from '@/libs/helpers/device'

    const props = withDefaults(
        defineProps<{
            tag?: string
            showOnHover?: boolean
            bodyClass?: string
            autoCloseDelayMillisAfterLeave?: number
            absolute?: boolean
            testId?: string
        }>(),
        {
            tag: 'div',
            bodyClass: undefined,
            autoCloseDelayMillisAfterLeave: 250
        }
    )

    const emit = defineEmits<{
        (e: 'open'): void
        (e: 'close'): void
    }>()

    const open = ref(false)
    const closeTimeoutId = ref<ReturnType<typeof setTimeout> | null>(null)

    const activatorBoundings = computed(() => ({
        onClick: (e: Event) => {
            e.preventDefault()
            e.stopPropagation()
            e.stopImmediatePropagation()
            open.value = !open.value
        },
        onMouseover: () => {
            if (closeTimeoutId.value) {
                clearInterval(closeTimeoutId.value)
            }

            if (props.showOnHover && !isTouchDevice()) {
                open.value = true
            }
        }
    }))

    watch(open, () => {
        if (open.value) emit('open')
        else emit('close')
    })

    function close() {
        closeTimeoutId.value = setTimeout(() => {
            open.value = false
        }, props.autoCloseDelayMillisAfterLeave)
    }

    defineExpose({ open })
</script>

<template>
    <Component
        :is="tag"
        class="dropdown"
        :class="{ 'dropdown--active': open, 'dropdown--absolute': absolute }"
        :data-testId="testId"
        @mouseleave="close"
        @mouseover="activatorBoundings.onMouseover"
    >
        <div class="dropdown__activator" @click="activatorBoundings.onClick">
            <slot name="activator" v-bind="{ open }"></slot>
        </div>
        <div v-if="open" class="dropdown__body" :class="bodyClass">
            <slot name="dropdown" v-bind="{ open, close }"></slot>
        </div>
    </Component>
</template>

<style scoped lang="scss">
    .dropdown {
        $block-selector: &;
        height: 100%;

        &--absolute {
            position: relative;
        }

        &--active {
            z-index: 101;
        }

        &__body {
            z-index: 100;
            //margin-top: 1rem;

            #{$block-selector}--absolute & {
                position: absolute;
                bottom: 0;
                transform: translateY(100%);
            }
        }

        &__activator {
            cursor: pointer;
            height: 100%;
            display: flex;
            align-items: center;
        }

        /*
        &--enter-active {
          transition: opacity 350ms;
        }

        &--leave-active {
            transition: opacity 0ms;
        }

        &--enter-from,
        &--leave-to {
            opacity: 0;
        }*/
    }
</style>
