<script setup lang="ts">
    import useBreakpoints from '@/libs/compositions/useBreakpoints'
    import HeaderDesktop from '@/components/layouts/network/headers/public-header/partials/HeaderDesktop.vue'
    import HeaderMobile from '@/components/layouts/network/headers/public-header/partials/HeaderMobile.vue'

    const { breakpoints } = useBreakpoints()
</script>

<template>
    <Component :is="breakpoints.xs || breakpoints.sm || breakpoints.md ? HeaderMobile : HeaderDesktop" class="header" />
</template>

<style lang="scss" scoped>
    .header {
        position: relative;
        z-index: 100;
    }
</style>
