<script setup lang="ts">
    import { useI18n } from 'vue-i18n'
    import { useRoute } from 'vue-router'
    import { useAuthStore } from '@/libs/stores/auth.store'
    import { onMounted } from 'vue'
    import jwtDecode from 'jwt-decode'
    import AuthApi from '@/libs/api/Auth.api'
    import axios, { AxiosError } from 'axios'
    import { useMutation } from '@tanstack/vue-query'
    import { initGoogleOAuth } from '@/libs/vendors/google/oauth/google-oauth'
    import { StorageKey } from '@/vars/StorageAttr'
    import SharedStorage from '@/libs/core/SharedStorage'
    import { GoogleOAuthLoginForm } from '@/types/forms/auth-api/GoogleOAuthLoginForm.type'

    const props = defineProps<{
        type: 'login' | 'register'
        size?: 'small' | 'large'
    }>()

    const emit = defineEmits<{
        (e: 'error', { status, message }: { status: number; message: string }): void
        (e: 'pending'): void
        (e: 'success'): void
    }>()

    const { t } = useI18n()
    const route = useRoute()
    const store = useAuthStore()

    const clientId = import.meta.env.VITE_APP_GOOGLE_AUTH_CLIENT_ID

    onMounted(async () => {
        await initGoogleOAuth()
    })

    const { mutate: register } = useMutation({
        mutationFn: ({
            idToken,
            authCode,
            email,
            firstName,
            lastName
        }: {
            idToken: string
            authCode: string
            email: string
            firstName: string
            lastName: string
        }) => {
            return AuthApi.register({
                context: (route.query.context as string) || '',
                suw: 'google',
                googleIdToken: idToken,
                googleAuthCode: authCode,
                email,
                firstName,
                lastName,
                persona: SharedStorage.get(StorageKey.PERSONA_SELECTED) || undefined
            })
        },
        onSuccess: async (registerData) => {
            store.updateTokens(registerData.meta.jwt.token, registerData.meta.jwt.refreshToken)
            await store.getMe()
            emit('success')
        },
        onError: (error) => {
            if (axios.isAxiosError(error)) {
                const code = error?.response?.status

                switch (code) {
                    case 409:
                        emit('error', { status: code, message: t('register_errors_social409') })
                        break
                    default:
                        emit('error', { status: code || 400, message: t('register_errors_google') })
                }
            }
        }
    })

    const { mutate: login } = useMutation<
        Awaited<ReturnType<typeof AuthApi.oAuthGoogleLogin>>,
        AxiosError,
        GoogleOAuthLoginForm
    >({
        mutationFn: (form) => AuthApi.oAuthGoogleLogin(form),
        onSuccess: async (loginData) => {
            store.updateTokens(loginData.meta.jwt.token, loginData.meta.jwt.refreshToken)
            await store.getMe()
            emit('success')
        },
        onError: (error) => {
            if (axios.isAxiosError(error)) {
                const code = error?.response?.status

                switch (code) {
                    case 404:
                        emit('error', { status: code, message: t('login_errors_404') })
                        break
                    default:
                        emit('error', { status: code || 400, message: t('register_errors_google') })
                }
            }
        }
    })

    const onClick = async () => {
        const loadGApi = () => {
            return new Promise((resolve) => {
                window.gapi?.load('client:auth2', () => {
                    resolve(true)
                })
            })
        }

        emit('pending')

        try {
            if (!window?.gapi?.auth2?.authorize) {
                await loadGApi()
            }

            window?.gapi?.auth2?.authorize(
                {
                    client_id: `${clientId}.apps.googleusercontent.com`,
                    scope: 'email profile openid',
                    response_type: 'id_token token code'
                },
                (response: { id_token: string; code: string }) => {
                    if (props.type === 'login') {
                        login({ googleIdToken: response.id_token, googleAuthCode: response.code })
                    }

                    if (props.type === 'register') {
                        if (!response.id_token) {
                            console.error('Missing "response.id_token"') // Log dans sentry pour plus d'informations
                        }

                        const decodedToken: { email: string; given_name: string; family_name: string } = jwtDecode(
                            response.id_token
                        )
                        register({
                            idToken: response.id_token,
                            authCode: response.code,
                            email: decodedToken.email,
                            firstName: decodedToken.given_name,
                            lastName: decodedToken.family_name
                        })
                    }
                }
            )
        } catch (e) {
            console.error(e)
            emit('error', { status: 400, message: t('register-errors-google') })
        }
    }
</script>

<template>
    <div :class="[size === 'large' ? 'google__large' : 'google__small']" @click="onClick">
        <img src="@/assets/images/logos/google.png" :alt="t('logo')" class="google__logo" />
        <span v-if="size === 'large'" class="text-small"> {{ t('login') }}</span>
    </div>
</template>

<style scoped lang="scss">
    @import '@/libs/sass/vars';
    .google {
        &__small {
            width: 2.5rem;
            height: 2.5rem;
            border-radius: 50%;
            margin: 0 var(--scale-2);
            background-color: transparent;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            border: var(--scale-px) solid var(--theme-color-gray--05);
            &:hover {
                border: var(--scale-px) solid var(--theme-color-gray--03);
            }
        }
        &__logo {
            width: 1.5rem;
        }

        &__large {
            display: flex;
            width: auto;
            background-color: initial;
            min-height: 2.8125em;
            border: var(--scale-px) solid;
            border-color: var(--theme-border-color);
            margin: 0 var(--scale-2);
            margin-bottom: var(--scale-6r);
            align-items: center;
            justify-content: center;
            gap: var(--scale-4r);
            cursor: pointer;
            padding: var(--scale-2);
        }
    }
</style>

<i18n lang="json">
{
    "fr": {
        "logo": "Google logo",
        "register_errors_google": "Une erreur est survenue avec Google, veuillez réessayer",
        "login_errors_404": "Aucun compte Frenchfounders associé à votre compte Google n'a été trouvé. Vérifiez que l'adresse e-mail des deux comptes correspondent.",
        "register_errors_social409": "L'e-mail rattaché à votre compte est déjà utilisé sur Frenchfounders",
        "login": "Se connecter avec Google"
    },
    "en": {
        "logo": "Google logo",
        "register_errors_google": "An error occurred with Google, please try again",
        "login_errors_404": "No Frenchfounders account associated with your Google account has been found.Check that the e-mail address of the two accounts correspond.",
        "register_errors_social409": "The email associated with your account is already used on Frenchfounders.",
        "login": "Sign in with Google"
    }
}
</i18n>
