<script setup lang="ts">
    import { useForm } from 'vee-validate'
    import { useI18n } from 'vue-i18n'
    import useBreakpoints from '@/libs/compositions/useBreakpoints'
    import { computed, ref } from 'vue'
    import InputFooter from './InputFooter.vue'
    import ZappierApi from '@/libs/api/Zappier.api'
    import { useMutation } from '@tanstack/vue-query'
    import useNotification from '@/libs/compositions/useNotification'
    import { ToastType } from '@/vars/ToastAttr'
    import BaseIcon from '@/components/ui/basics/BaseIcon.vue'
    import InfiniteLoader from '@/components/ui/loaders/InfiniteLoader.vue'

    const { t } = useI18n()
    const { breakpoints } = useBreakpoints()
    const { meta, handleSubmit } = useForm({
        initialValues: {
            email: ''
        }
    })
    const isMobileScreen = computed(() => {
        return breakpoints.value.xs || breakpoints.value.sm
    })
    const { toast } = useNotification()

    const isRegisterToNewsLetter = ref(false)

    const { mutate, isPending } = useMutation({
        mutationFn: (email: string) => ZappierApi.postEmailNewsLetter(email),
        onSuccess: () => {
            toast({
                type: ToastType.SUCCESS,
                title: t('success')
            })
            isRegisterToNewsLetter.value = true
        },
        onError: () => {
            toast({
                type: ToastType.ERROR,
                title: t('error_generic')
            })
        }
    })

    const onSubmit = handleSubmit(({ email }) => {
        if (meta.value.valid) {
            mutate(email)
        }
    })
</script>

<template>
    <div class="connect">
        <div v-safe-html="isMobileScreen ? t('title.default') : t('title.break')" class="title-2"></div>
        <div v-if="isRegisterToNewsLetter" class="mt-10">{{ t('already_register') }}</div>
        <InfiniteLoader v-else-if="isPending" />
        <InputFooter
            v-else
            name="email"
            icon-name="arrow-forward"
            :placeholder="t('contact.placeholder')"
            class="mt-10"
            rules="required|email"
        >
            <template #description>{{ t('contact.description') }}</template>
            <template #icon>
                <BaseIcon
                    name="arrow-forward"
                    class="connect__icon"
                    :class="{
                        'connect__icon--disabled': !meta.valid
                    }"
                    @click="onSubmit"
                />
            </template>
        </InputFooter>
    </div>
</template>

<style lang="scss" scoped>
    .connect {
        &__icon {
            cursor: pointer;
            &--disabled {
                cursor: not-allowed;
            }
        }
    }
</style>

<i18n lang="json">
{
    "fr": {
        "title": { "default": "Restons connectés", "break": "Restons<br>connectés" },
        "contact": {
            "placeholder": "Votre email",
            "description": "Nous collectons et traitons vos données personnelles afin de mieux répondre à vos demandes. Vous pouvez à tout moment vous désinscrire via un lien de désabonnement dans notre newsletter."
        },
        "success": "Votre inscription a bien été prise en compte. Merci !",
        "error_generic": "Une erreur s'est produite, veuillez réessayer ultérieurement.",
        "already_register": "Merci de vous être inscrit à notre newsletter"
    },
    "en": {
        "title": { "default": "Let's stay connected", "break": "Let's stay<br>connected" },
        "contact": {
            "placeholder": "Your email",
            "description": "We collect and process your personal data in order to better respond to your requests.You can unsubscribe at any time via an unsubscribing link in our newsletter."
        },
        "success": "Your registration has been taken into account. Thanks !",
        "error_generic": "An error has occurred, please try again later.",
        "already_register": "Thank you for your subscription to our newsletter"
    }
}
</i18n>
