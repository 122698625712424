<script setup lang="ts">
    import { ref, watch } from 'vue'
    import SmartActionCard from '@/components/partials/network/smart-actions/SmartActionCard.vue'
    import { SmartAction } from '@/types/response/members-api/SmartActions.type'
    import { useI18n } from 'vue-i18n'
    import ModalWhatsNew from '@/components/partials/common/WhatsNew/ModalWhatsNew.vue'
    import useWhatsNew from '@/libs/compositions/useWhatsNew'
    import { useSegment } from '@/libs/vendors/segment/segment'

    const props = defineProps<{
        action: SmartAction
    }>()

    const emit = defineEmits<{
        remove: [action: typeof props.action]
    }>()

    const { t } = useI18n()
    const { productUpdates } = useWhatsNew()
    const { analytics } = useSegment()
    const isProductUpdateOpen = ref(false)

    watch(isProductUpdateOpen, (isNowOpen, wasOpen) => {
        if (wasOpen && !isNowOpen) {
            emit('remove', props.action)
        } else if (isNowOpen) {
            analytics.track('community:whats_new')
        }
    })

    defineExpose({
        name: 'SmartActionWhatsNew',
        open: isProductUpdateOpen
    })
</script>

<template>
    <div>
        <SmartActionCard
            :action="action"
            :infos="{ icon: 'rocket_launch', title: t('title'), subtitle: t('subtitle') }"
            @remove="$emit('remove', action)"
            @click="isProductUpdateOpen = true"
        />
        <ModalWhatsNew v-model="isProductUpdateOpen" :product-updates="productUpdates?.data || []" />
    </div>
</template>

<i18n lang="json">
{
    "fr": {
        "title": "Découvrez les nouveautés",
        "subtitle": "Voir les changements"
    },
    "en": {
        "title": "Discover the new features",
        "subtitle": "See the list of changes"
    }
}
</i18n>
