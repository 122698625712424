type PhonePrefixCountry = {
    countryName: string
    countryNameFr: string
    phonePrefix: string
    countryCode: string
}

/**
 * @debt quality "Pour harmoniser avec les autres constantes, voir si on le passe avec PascalCase (PhonePrefixList)"
 */

const phonePrefixList: PhonePrefixCountry[] = [
    {
        countryName: 'Afghanistan',
        countryNameFr: 'Afghanistan',
        phonePrefix: '+93',
        countryCode: 'AF'
    },
    {
        countryName: 'Aland Islands',
        countryNameFr: 'Îles Åland',
        phonePrefix: '+358',
        countryCode: 'AX'
    },
    {
        countryName: 'Albania',
        countryNameFr: 'Albanie',
        phonePrefix: '+355',
        countryCode: 'AL'
    },
    {
        countryName: 'Algeria',
        countryNameFr: 'Algérie',
        phonePrefix: '+213',
        countryCode: 'DZ'
    },
    {
        countryName: 'AmericanSamoa',
        countryNameFr: 'Samoa américaines',
        phonePrefix: '+1684',
        countryCode: 'AS'
    },
    {
        countryName: 'Andorra',
        countryNameFr: 'Andorre',
        phonePrefix: '+376',
        countryCode: 'AD'
    },
    {
        countryName: 'Angola',
        countryNameFr: 'Angola',
        phonePrefix: '+244',
        countryCode: 'AO'
    },
    {
        countryName: 'Anguilla',
        countryNameFr: 'Anguilla',
        phonePrefix: '+1264',
        countryCode: 'AI'
    },
    {
        countryName: 'Antarctica',
        countryNameFr: 'Antarctique',
        phonePrefix: '+672',
        countryCode: 'AQ'
    },
    {
        countryName: 'Antigua and Barbuda',
        countryNameFr: 'Antigua-et-Barbuda',
        phonePrefix: '+1268',
        countryCode: 'AG'
    },
    {
        countryName: 'Argentina',
        countryNameFr: 'Argentine',
        phonePrefix: '+54',
        countryCode: 'AR'
    },
    {
        countryName: 'Armenia',
        countryNameFr: 'Arménie',
        phonePrefix: '+374',
        countryCode: 'AM'
    },
    {
        countryName: 'Aruba',
        countryNameFr: 'Aruba',
        phonePrefix: '+297',
        countryCode: 'AW'
    },
    {
        countryName: 'Australia',
        countryNameFr: 'Australie',
        phonePrefix: '+61',
        countryCode: 'AU'
    },
    {
        countryName: 'Austria',
        countryNameFr: 'Autriche',
        phonePrefix: '+43',
        countryCode: 'AT'
    },
    {
        countryName: 'Azerbaijan',
        countryNameFr: 'Azerbaïdjan',
        phonePrefix: '+994',
        countryCode: 'AZ'
    },
    {
        countryName: 'Bahamas',
        countryNameFr: 'Bahamas',
        phonePrefix: '+1242',
        countryCode: 'BS'
    },
    {
        countryName: 'Bahrain',
        countryNameFr: 'Bahreïn',
        phonePrefix: '+973',
        countryCode: 'BH'
    },
    {
        countryName: 'Bangladesh',
        countryNameFr: 'Bangladesh',
        phonePrefix: '+880',
        countryCode: 'BD'
    },
    {
        countryName: 'Barbados',
        countryNameFr: 'Barbade',
        phonePrefix: '+1246',
        countryCode: 'BB'
    },
    {
        countryName: 'Belarus',
        countryNameFr: 'Biélorussie',
        phonePrefix: '+375',
        countryCode: 'BY'
    },
    {
        countryName: 'Belgium',
        countryNameFr: 'Belgique',
        phonePrefix: '+32',
        countryCode: 'BE'
    },
    {
        countryName: 'Belize',
        countryNameFr: 'Belize',
        phonePrefix: '+501',
        countryCode: 'BZ'
    },
    {
        countryName: 'Benin',
        countryNameFr: 'Bénin',
        phonePrefix: '+229',
        countryCode: 'BJ'
    },
    {
        countryName: 'Bermuda',
        countryNameFr: 'Bermudes',
        phonePrefix: '+1441',
        countryCode: 'BM'
    },
    {
        countryName: 'Bhutan',
        countryNameFr: 'Bhoutan',
        phonePrefix: '+975',
        countryCode: 'BT'
    },
    {
        countryName: 'Bolivia, Plurinational State of',
        countryNameFr: 'Bolivie',
        phonePrefix: '+591',
        countryCode: 'BO'
    },
    {
        countryName: 'Bosnia and Herzegovina',
        countryNameFr: 'Bosnie-Herzégovine',
        phonePrefix: '+387',
        countryCode: 'BA'
    },
    {
        countryName: 'Botswana',
        countryNameFr: 'Botswana',
        phonePrefix: '+267',
        countryCode: 'BW'
    },
    {
        countryName: 'Brazil',
        countryNameFr: 'Brésil',
        phonePrefix: '+55',
        countryCode: 'BR'
    },
    {
        countryName: 'British Indian Ocean Territory',
        countryNameFr: "Territoire britannique de l'océan Indien",
        phonePrefix: '+246',
        countryCode: 'IO'
    },
    {
        countryName: 'Brunei Darussalam',
        countryNameFr: 'Brunéi Darussalam',
        phonePrefix: '+673',
        countryCode: 'BN'
    },
    {
        countryName: 'Bulgaria',
        countryNameFr: 'Bulgarie',
        phonePrefix: '+359',
        countryCode: 'BG'
    },
    {
        countryName: 'Burkina Faso',
        countryNameFr: 'Burkina Faso',
        phonePrefix: '+226',
        countryCode: 'BF'
    },
    {
        countryName: 'Burundi',
        countryNameFr: 'Burundi',
        phonePrefix: '+257',
        countryCode: 'BI'
    },
    {
        countryName: 'Cambodia',
        countryNameFr: 'Cambodge',
        phonePrefix: '+855',
        countryCode: 'KH'
    },
    {
        countryName: 'Cameroon',
        countryNameFr: 'Cameroun',
        phonePrefix: '+237',
        countryCode: 'CM'
    },
    {
        countryName: 'Canada',
        countryNameFr: 'Canada',
        phonePrefix: '+1',
        countryCode: 'CA'
    },
    {
        countryName: 'Cape Verde',
        countryNameFr: 'Cap-Vert',
        phonePrefix: '+238',
        countryCode: 'CV'
    },
    {
        countryName: 'Cayman Islands',
        countryNameFr: 'Îles Caïmans',
        phonePrefix: '+345',
        countryCode: 'KY'
    },
    {
        countryName: 'Central African Republic',
        countryNameFr: 'République centrafricaine',
        phonePrefix: '+236',
        countryCode: 'CF'
    },
    {
        countryName: 'Chad',
        countryNameFr: 'Tchad',
        phonePrefix: '+235',
        countryCode: 'TD'
    },
    {
        countryName: 'Chile',
        countryNameFr: 'Chili',
        phonePrefix: '+56',
        countryCode: 'CL'
    },
    {
        countryName: 'China',
        countryNameFr: 'Chine',
        phonePrefix: '+86',
        countryCode: 'CN'
    },
    {
        countryName: 'Christmas Island',
        countryNameFr: 'Île Christmas',
        phonePrefix: '+61',
        countryCode: 'CX'
    },
    {
        countryName: 'Cocos (Keeling) Islands',
        countryNameFr: 'Îles Cocos',
        phonePrefix: '+61',
        countryCode: 'CC'
    },
    {
        countryName: 'Colombia',
        countryNameFr: 'Colombie',
        phonePrefix: '+57',
        countryCode: 'CO'
    },
    {
        countryName: 'Comoros',
        countryNameFr: 'Comores',
        phonePrefix: '+269',
        countryCode: 'KM'
    },
    {
        countryName: 'Congo',
        countryNameFr: 'Congo',
        phonePrefix: '+242',
        countryCode: 'CG'
    },
    {
        countryName: 'Congo, The Democratic Republic of the Congo',
        countryNameFr: 'République démocratique du Congo',
        phonePrefix: '+243',
        countryCode: 'CD'
    },
    {
        countryName: 'Cook Islands',
        countryNameFr: 'Îles Cook',
        phonePrefix: '+682',
        countryCode: 'CK'
    },
    {
        countryName: 'Costa Rica',
        countryNameFr: 'Costa Rica',
        phonePrefix: '+506',
        countryCode: 'CR'
    },
    {
        countryName: "Cote d'Ivoire",
        countryNameFr: "Côte d'Ivoire",
        phonePrefix: '+225',
        countryCode: 'CI'
    },
    {
        countryName: 'Croatia',
        countryNameFr: 'Croatie',
        phonePrefix: '+385',
        countryCode: 'HR'
    },
    {
        countryName: 'Cuba',
        countryNameFr: 'Cuba',
        phonePrefix: '+53',
        countryCode: 'CU'
    },
    {
        countryName: 'Cyprus',
        countryNameFr: 'Chypre',
        phonePrefix: '+357',
        countryCode: 'CY'
    },
    {
        countryName: 'Czech Republic',
        countryNameFr: 'République tchèque',
        phonePrefix: '+420',
        countryCode: 'CZ'
    },
    {
        countryName: 'Denmark',
        countryNameFr: 'Danemark',
        phonePrefix: '+45',
        countryCode: 'DK'
    },
    {
        countryName: 'Djibouti',
        countryNameFr: 'Djibouti',
        phonePrefix: '+253',
        countryCode: 'DJ'
    },
    {
        countryName: 'Dominica',
        countryNameFr: 'Dominique',
        phonePrefix: '+1767',
        countryCode: 'DM'
    },
    {
        countryName: 'Dominican Republic',
        countryNameFr: 'République dominicaine',
        phonePrefix: '+1849',
        countryCode: 'DO'
    },
    {
        countryName: 'Ecuador',
        countryNameFr: 'Équateur',
        phonePrefix: '+593',
        countryCode: 'EC'
    },
    {
        countryName: 'Egypt',
        countryNameFr: 'Égypte',
        phonePrefix: '+20',
        countryCode: 'EG'
    },
    {
        countryName: 'El Salvador',
        countryNameFr: 'Salvador',
        phonePrefix: '+503',
        countryCode: 'SV'
    },
    {
        countryName: 'Equatorial Guinea',
        countryNameFr: 'Guinée équatoriale',
        phonePrefix: '+240',
        countryCode: 'GQ'
    },
    {
        countryName: 'Eritrea',
        countryNameFr: 'Érythrée',
        phonePrefix: '+291',
        countryCode: 'ER'
    },
    {
        countryName: 'Estonia',
        countryNameFr: 'Estonie',
        phonePrefix: '+372',
        countryCode: 'EE'
    },
    {
        countryName: 'Ethiopia',
        countryNameFr: 'Éthiopie',
        phonePrefix: '+251',
        countryCode: 'ET'
    },
    {
        countryName: 'Falkland Islands (Malvinas)',
        countryNameFr: 'Îles Malouines',
        phonePrefix: '+500',
        countryCode: 'FK'
    },
    {
        countryName: 'Faroe Islands',
        countryNameFr: 'Îles Féroé',
        phonePrefix: '+298',
        countryCode: 'FO'
    },
    {
        countryName: 'Fiji',
        countryNameFr: 'Fidji',
        phonePrefix: '+679',
        countryCode: 'FJ'
    },
    {
        countryName: 'Finland',
        countryNameFr: 'Finlande',
        phonePrefix: '+358',
        countryCode: 'FI'
    },
    {
        countryName: 'France',
        countryNameFr: 'France',
        phonePrefix: '+33',
        countryCode: 'FR'
    },
    {
        countryName: 'French Guiana',
        countryNameFr: 'Guyane française',
        phonePrefix: '+594',
        countryCode: 'GF'
    },
    {
        countryName: 'French Polynesia',
        countryNameFr: 'Polynésie française',
        phonePrefix: '+689',
        countryCode: 'PF'
    },
    {
        countryName: 'Gabon',
        countryNameFr: 'Gabon',
        phonePrefix: '+241',
        countryCode: 'GA'
    },
    {
        countryName: 'Gambia',
        countryNameFr: 'Gambie',
        phonePrefix: '+220',
        countryCode: 'GM'
    },
    {
        countryName: 'Georgia',
        countryNameFr: 'Géorgie',
        phonePrefix: '+995',
        countryCode: 'GE'
    },
    {
        countryName: 'Germany',
        countryNameFr: 'Allemagne',
        phonePrefix: '+49',
        countryCode: 'DE'
    },
    {
        countryName: 'Ghana',
        countryNameFr: 'Ghana',
        phonePrefix: '+233',
        countryCode: 'GH'
    },
    {
        countryName: 'Gibraltar',
        countryNameFr: 'Gibraltar',
        phonePrefix: '+350',
        countryCode: 'GI'
    },
    {
        countryName: 'Greece',
        countryNameFr: 'Grèce',
        phonePrefix: '+30',
        countryCode: 'GR'
    },
    {
        countryName: 'Greenland',
        countryNameFr: 'Groenland',
        phonePrefix: '+299',
        countryCode: 'GL'
    },
    {
        countryName: 'Grenada',
        countryNameFr: 'Grenade',
        phonePrefix: '+1473',
        countryCode: 'GD'
    },
    {
        countryName: 'Guadeloupe',
        countryNameFr: 'Guadeloupe',
        phonePrefix: '+590',
        countryCode: 'GP'
    },
    {
        countryName: 'Guam',
        countryNameFr: 'Guam',
        phonePrefix: '+1671',
        countryCode: 'GU'
    },
    {
        countryName: 'Guatemala',
        countryNameFr: 'Guatemala',
        phonePrefix: '+502',
        countryCode: 'GT'
    },
    {
        countryName: 'Guernsey',
        countryNameFr: 'Guernesey',
        phonePrefix: '+44',
        countryCode: 'GG'
    },
    {
        countryName: 'Guinea',
        countryNameFr: 'Guinée',
        phonePrefix: '+224',
        countryCode: 'GN'
    },
    {
        countryName: 'Guinea-Bissau',
        countryNameFr: 'Guinée-Bissau',
        phonePrefix: '+245',
        countryCode: 'GW'
    },
    {
        countryName: 'Guyana',
        countryNameFr: 'Guyana',
        phonePrefix: '+595',
        countryCode: 'GY'
    },
    {
        countryName: 'Haiti',
        countryNameFr: 'Haïti',
        phonePrefix: '+509',
        countryCode: 'HT'
    },
    {
        countryName: 'Holy See (Vatican City State)',
        countryNameFr: 'Saint-Siège (État de la Cité du Vatican)',
        phonePrefix: '+379',
        countryCode: 'VA'
    },
    {
        countryName: 'Honduras',
        countryNameFr: 'Honduras',
        phonePrefix: '+504',
        countryCode: 'HN'
    },
    {
        countryName: 'Hong Kong',
        countryNameFr: 'Hong Kong',
        phonePrefix: '+852',
        countryCode: 'HK'
    },
    {
        countryName: 'Hungary',
        countryNameFr: 'Hongrie',
        phonePrefix: '+36',
        countryCode: 'HU'
    },
    {
        countryName: 'Iceland',
        countryNameFr: 'Islande',
        phonePrefix: '+354',
        countryCode: 'IS'
    },
    {
        countryName: 'India',
        countryNameFr: 'Inde',
        phonePrefix: '+91',
        countryCode: 'IN'
    },
    {
        countryName: 'Indonesia',
        countryNameFr: 'Indonésie',
        phonePrefix: '+62',
        countryCode: 'ID'
    },
    {
        countryName: 'Iran, Islamic Republic of Persian Gulf',
        countryNameFr: 'Iran',
        phonePrefix: '+98',
        countryCode: 'IR'
    },
    {
        countryName: 'Iraq',
        countryNameFr: 'Irak',
        phonePrefix: '+964',
        countryCode: 'IQ'
    },
    {
        countryName: 'Ireland',
        countryNameFr: 'Irlande',
        phonePrefix: '+353',
        countryCode: 'IE'
    },
    {
        countryName: 'Isle of Man',
        countryNameFr: 'Île de Man',
        phonePrefix: '+44',
        countryCode: 'IM'
    },
    {
        countryName: 'Israel',
        countryNameFr: 'Israël',
        phonePrefix: '+972',
        countryCode: 'IL'
    },
    {
        countryName: 'Italy',
        countryNameFr: 'Italie',
        phonePrefix: '+39',
        countryCode: 'IT'
    },
    {
        countryName: 'Jamaica',
        countryNameFr: 'Jamaïque',
        phonePrefix: '+1876',
        countryCode: 'JM'
    },
    {
        countryName: 'Japan',
        countryNameFr: 'Japon',
        phonePrefix: '+81',
        countryCode: 'JP'
    },
    {
        countryName: 'Jersey',
        countryNameFr: 'Jersey',
        phonePrefix: '+44',
        countryCode: 'JE'
    },
    {
        countryName: 'Jordan',
        countryNameFr: 'Jordanie',
        phonePrefix: '+962',
        countryCode: 'JO'
    },
    {
        countryName: 'Kazakhstan',
        countryNameFr: 'Kazakhstan',
        phonePrefix: '+77',
        countryCode: 'KZ'
    },
    {
        countryName: 'Kenya',
        countryNameFr: 'Kenya',
        phonePrefix: '+254',
        countryCode: 'KE'
    },
    {
        countryName: 'Kiribati',
        countryNameFr: 'Kiribati',
        phonePrefix: '+686',
        countryCode: 'KI'
    },
    {
        countryName: "Korea, Democratic People's Republic of Korea",
        countryNameFr: 'Corée du Nord',
        phonePrefix: '+850',
        countryCode: 'KP'
    },
    {
        countryName: 'Korea, Republic of South Korea',
        countryNameFr: 'Corée du Sud',
        phonePrefix: '+82',
        countryCode: 'KR'
    },
    {
        countryName: 'Kuwait',
        countryNameFr: 'Koweït',
        phonePrefix: '+965',
        countryCode: 'KW'
    },
    {
        countryName: 'Kyrgyzstan',
        countryNameFr: 'Kirghizistan',
        phonePrefix: '+996',
        countryCode: 'KG'
    },
    {
        countryName: 'Laos',
        countryNameFr: 'Laos',
        phonePrefix: '+856',
        countryCode: 'LA'
    },
    {
        countryName: 'Latvia',
        countryNameFr: 'Lettonie',
        phonePrefix: '+371',
        countryCode: 'LV'
    },
    {
        countryName: 'Lebanon',
        countryNameFr: 'Liban',
        phonePrefix: '+961',
        countryCode: 'LB'
    },
    {
        countryName: 'Lesotho',
        countryNameFr: 'Lesotho',
        phonePrefix: '+266',
        countryCode: 'LS'
    },
    {
        countryName: 'Liberia',
        countryNameFr: 'Libéria',
        phonePrefix: '+231',
        countryCode: 'LR'
    },
    {
        countryName: 'Libyan Arab Jamahiriya',
        countryNameFr: 'Libye',
        phonePrefix: '+218',
        countryCode: 'LY'
    },
    {
        countryName: 'Liechtenstein',
        countryNameFr: 'Liechtenstein',
        phonePrefix: '+423',
        countryCode: 'LI'
    },
    {
        countryName: 'Lithuania',
        countryNameFr: 'Lituanie',
        phonePrefix: '+370',
        countryCode: 'LT'
    },
    {
        countryName: 'Luxembourg',
        countryNameFr: 'Luxembourg',
        phonePrefix: '+352',
        countryCode: 'LU'
    },
    {
        countryName: 'Macao',
        countryNameFr: 'Macao',
        phonePrefix: '+853',
        countryCode: 'MO'
    },
    {
        countryName: 'Macedonia',
        countryNameFr: 'Macédoine',
        phonePrefix: '+389',
        countryCode: 'MK'
    },
    {
        countryName: 'Madagascar',
        countryNameFr: 'Madagascar',
        phonePrefix: '+261',
        countryCode: 'MG'
    },
    {
        countryName: 'Malawi',
        countryNameFr: 'Malawi',
        phonePrefix: '+265',
        countryCode: 'MW'
    },
    {
        countryName: 'Malaysia',
        countryNameFr: 'Malaisie',
        phonePrefix: '+60',
        countryCode: 'MY'
    },
    {
        countryName: 'Maldives',
        countryNameFr: 'Maldives',
        phonePrefix: '+960',
        countryCode: 'MV'
    },
    {
        countryName: 'Mali',
        countryNameFr: 'Mali',
        phonePrefix: '+223',
        countryCode: 'ML'
    },
    {
        countryName: 'Malta',
        countryNameFr: 'Malte',
        phonePrefix: '+356',
        countryCode: 'MT'
    },
    {
        countryName: 'Marshall Islands',
        countryNameFr: 'Îles Marshall',
        phonePrefix: '+692',
        countryCode: 'MH'
    },
    {
        countryName: 'Martinique',
        countryNameFr: 'Martinique',
        phonePrefix: '+596',
        countryCode: 'MQ'
    },
    {
        countryName: 'Mauritania',
        countryNameFr: 'Mauritanie',
        phonePrefix: '+222',
        countryCode: 'MR'
    },
    {
        countryName: 'Mauritius',
        countryNameFr: 'Maurice',
        phonePrefix: '+230',
        countryCode: 'MU'
    },
    {
        countryName: 'Mayotte',
        countryNameFr: 'Mayotte',
        phonePrefix: '+262',
        countryCode: 'YT'
    },
    {
        countryName: 'Mexico',
        countryNameFr: 'Mexique',
        phonePrefix: '+52',
        countryCode: 'MX'
    },
    {
        countryName: 'Micronesia, Federated States of Micronesia',
        countryNameFr: 'Micronésie',
        phonePrefix: '+691',
        countryCode: 'FM'
    },
    {
        countryName: 'Moldova',
        countryNameFr: 'Moldavie',
        phonePrefix: '+373',
        countryCode: 'MD'
    },
    {
        countryName: 'Monaco',
        countryNameFr: 'Monaco',
        phonePrefix: '+377',
        countryCode: 'MC'
    },
    {
        countryName: 'Mongolia',
        countryNameFr: 'Mongolie',
        phonePrefix: '+976',
        countryCode: 'MN'
    },
    {
        countryName: 'Montenegro',
        countryNameFr: 'Monténégro',
        phonePrefix: '+382',
        countryCode: 'ME'
    },
    {
        countryName: 'Montserrat',
        countryNameFr: 'Montserrat',
        phonePrefix: '+1664',
        countryCode: 'MS'
    },
    {
        countryName: 'Morocco',
        countryNameFr: 'Maroc',
        phonePrefix: '+212',
        countryCode: 'MA'
    },
    {
        countryName: 'Mozambique',
        countryNameFr: 'Mozambique',
        phonePrefix: '+258',
        countryCode: 'MZ'
    },
    {
        countryName: 'Myanmar',
        countryNameFr: 'Myanmar',
        phonePrefix: '+95',
        countryCode: 'MM'
    },
    {
        countryName: 'Namibia',
        countryNameFr: 'Namibie',
        phonePrefix: '+264',
        countryCode: 'NA'
    },
    {
        countryName: 'Nauru',
        countryNameFr: 'Nauru',
        phonePrefix: '+674',
        countryCode: 'NR'
    },
    {
        countryName: 'Nepal',
        countryNameFr: 'Népal',
        phonePrefix: '+977',
        countryCode: 'NP'
    },
    {
        countryName: 'Netherlands',
        countryNameFr: 'Pays-Bas',
        phonePrefix: '+31',
        countryCode: 'NL'
    },
    {
        countryName: 'Netherlands Antilles',
        countryNameFr: 'Antilles néerlandaises',
        phonePrefix: '+599',
        countryCode: 'AN'
    },
    {
        countryName: 'New Caledonia',
        countryNameFr: 'Nouvelle-Calédonie',
        phonePrefix: '+687',
        countryCode: 'NC'
    },
    {
        countryName: 'New Zealand',
        countryNameFr: 'Nouvelle-Zélande',
        phonePrefix: '+64',
        countryCode: 'NZ'
    },
    {
        countryName: 'Nicaragua',
        countryNameFr: 'Nicaragua',
        phonePrefix: '+505',
        countryCode: 'NI'
    },
    {
        countryName: 'Niger',
        countryNameFr: 'Niger',
        phonePrefix: '+227',
        countryCode: 'NE'
    },
    {
        countryName: 'Nigeria',
        countryNameFr: 'Nigeria',
        phonePrefix: '+234',
        countryCode: 'NG'
    },
    {
        countryName: 'Niue',
        countryNameFr: 'Niue',
        phonePrefix: '+683',
        countryCode: 'NU'
    },
    {
        countryName: 'Norfolk Island',
        countryNameFr: 'Île Norfolk',
        phonePrefix: '+672',
        countryCode: 'NF'
    },
    {
        countryName: 'Northern Mariana Islands',
        countryNameFr: 'Îles Mariannes du Nord',
        phonePrefix: '+1670',
        countryCode: 'MP'
    },
    {
        countryName: 'Norway',
        countryNameFr: 'Norvège',
        phonePrefix: '+47',
        countryCode: 'NO'
    },
    {
        countryName: 'Oman',
        countryNameFr: 'Oman',
        phonePrefix: '+968',
        countryCode: 'OM'
    },
    {
        countryName: 'Pakistan',
        countryNameFr: 'Pakistan',
        phonePrefix: '+92',
        countryCode: 'PK'
    },
    {
        countryName: 'Palau',
        countryNameFr: 'Palaos',
        phonePrefix: '+680',
        countryCode: 'PW'
    },
    {
        countryName: 'Palestinian Territory, Occupied',
        countryNameFr: 'Territoire palestinien occupé',
        phonePrefix: '+970',
        countryCode: 'PS'
    },
    {
        countryName: 'Panama',
        countryNameFr: 'Panama',
        phonePrefix: '+507',
        countryCode: 'PA'
    },
    {
        countryName: 'Papua New Guinea',
        countryNameFr: 'Papouasie-Nouvelle-Guinée',
        phonePrefix: '+675',
        countryCode: 'PG'
    },
    {
        countryName: 'Paraguay',
        countryNameFr: 'Paraguay',
        phonePrefix: '+595',
        countryCode: 'PY'
    },
    {
        countryName: 'Peru',
        countryNameFr: 'Pérou',
        phonePrefix: '+51',
        countryCode: 'PE'
    },
    {
        countryName: 'Philippines',
        countryNameFr: 'Philippines',
        phonePrefix: '+63',
        countryCode: 'PH'
    },
    {
        countryName: 'Pitcairn',
        countryNameFr: 'Îles Pitcairn',
        phonePrefix: '+872',
        countryCode: 'PN'
    },
    {
        countryName: 'Poland',
        countryNameFr: 'Pologne',
        phonePrefix: '+48',
        countryCode: 'PL'
    },
    {
        countryName: 'Portugal',
        countryNameFr: 'Portugal',
        phonePrefix: '+351',
        countryCode: 'PT'
    },
    {
        countryName: 'Puerto Rico',
        countryNameFr: 'Porto Rico',
        phonePrefix: '+1939',
        countryCode: 'PR'
    },
    {
        countryName: 'Qatar',
        countryNameFr: 'Qatar',
        phonePrefix: '+974',
        countryCode: 'QA'
    },
    {
        countryName: 'Romania',
        countryNameFr: 'Roumanie',
        phonePrefix: '+40',
        countryCode: 'RO'
    },
    {
        countryName: 'Russia',
        countryNameFr: 'Russie',
        phonePrefix: '+7',
        countryCode: 'RU'
    },
    {
        countryName: 'Rwanda',
        countryNameFr: 'Rwanda',
        phonePrefix: '+250',
        countryCode: 'RW'
    },
    {
        countryName: 'Reunion',
        countryNameFr: 'Réunion',
        phonePrefix: '+262',
        countryCode: 'RE'
    },
    {
        countryName: 'Saint Barthelemy',
        countryNameFr: 'Saint-Barthélemy',
        phonePrefix: '+590',
        countryCode: 'BL'
    },
    {
        countryName: 'Saint Helena, Ascension and Tristan Da Cunha',
        countryNameFr: 'Sainte-Hélène, Ascension et Tristan da Cunha',
        phonePrefix: '+290',
        countryCode: 'SH'
    },
    {
        countryName: 'Saint Kitts and Nevis',
        countryNameFr: 'Saint-Christophe-et-Niévès',
        phonePrefix: '+1869',
        countryCode: 'KN'
    },
    {
        countryName: 'Saint Lucia',
        countryNameFr: 'Sainte-Lucie',
        phonePrefix: '+1758',
        countryCode: 'LC'
    },
    {
        countryName: 'Saint Martin',
        countryNameFr: 'Saint-Martin',
        phonePrefix: '+590',
        countryCode: 'MF'
    },
    {
        countryName: 'Saint Pierre and Miquelon',
        countryNameFr: 'Saint-Pierre-et-Miquelon',
        phonePrefix: '+508',
        countryCode: 'PM'
    },
    {
        countryName: 'Saint Vincent and the Grenadines',
        countryNameFr: 'Saint-Vincent-et-les-Grenadines',
        phonePrefix: '+1784',
        countryCode: 'VC'
    },
    {
        countryName: 'Samoa',
        countryNameFr: 'Samoa',
        phonePrefix: '+685',
        countryCode: 'WS'
    },
    {
        countryName: 'San Marino',
        countryNameFr: 'Saint-Marin',
        phonePrefix: '+378',
        countryCode: 'SM'
    },
    {
        countryName: 'Sao Tome and Principe',
        countryNameFr: 'Sao Tomé-et-Principe',
        phonePrefix: '+239',
        countryCode: 'ST'
    },
    {
        countryName: 'Saudi Arabia',
        countryNameFr: 'Arabie saoudite',
        phonePrefix: '+966',
        countryCode: 'SA'
    },
    {
        countryName: 'Senegal',
        countryNameFr: 'Sénégal',
        phonePrefix: '+221',
        countryCode: 'SN'
    },
    {
        countryName: 'Serbia',
        countryNameFr: 'Serbie',
        phonePrefix: '+381',
        countryCode: 'RS'
    },
    {
        countryName: 'Seychelles',
        countryNameFr: 'Seychelles',
        phonePrefix: '+248',
        countryCode: 'SC'
    },
    {
        countryName: 'Sierra Leone',
        countryNameFr: 'Sierra Leone',
        phonePrefix: '+232',
        countryCode: 'SL'
    },
    {
        countryName: 'Singapore',
        countryNameFr: 'Singapour',
        phonePrefix: '+65',
        countryCode: 'SG'
    },
    {
        countryName: 'Slovakia',
        countryNameFr: 'Slovaquie',
        phonePrefix: '+421',
        countryCode: 'SK'
    },
    {
        countryName: 'Slovenia',
        countryNameFr: 'Slovénie',
        phonePrefix: '+386',
        countryCode: 'SI'
    },
    {
        countryName: 'Solomon Islands',
        countryNameFr: 'Îles Salomon',
        phonePrefix: '+677',
        countryCode: 'SB'
    },
    {
        countryName: 'Somalia',
        countryNameFr: 'Somalie',
        phonePrefix: '+252',
        countryCode: 'SO'
    },
    {
        countryName: 'South Africa',
        countryNameFr: 'Afrique du Sud',
        phonePrefix: '+27',
        countryCode: 'ZA'
    },
    {
        countryName: 'South Sudan',
        countryNameFr: 'Soudan du Sud',
        phonePrefix: '+211',
        countryCode: 'SS'
    },
    {
        countryName: 'South Georgia and the South Sandwich Islands',
        countryNameFr: 'Géorgie du Sud-et-les Îles Sandwich du Sud',
        phonePrefix: '+500',
        countryCode: 'GS'
    },
    {
        countryName: 'Spain',
        countryNameFr: 'Espagne',
        phonePrefix: '+34',
        countryCode: 'ES'
    },
    {
        countryName: 'Sri Lanka',
        countryNameFr: 'Sri Lanka',
        phonePrefix: '+94',
        countryCode: 'LK'
    },
    {
        countryName: 'Sudan',
        countryNameFr: 'Soudan',
        phonePrefix: '+249',
        countryCode: 'SD'
    },
    {
        countryName: 'Suriname',
        countryNameFr: 'Suriname',
        phonePrefix: '+597',
        countryCode: 'SR'
    },
    {
        countryName: 'Svalbard and Jan Mayen',
        countryNameFr: 'Svalbard et Jan Mayen',
        phonePrefix: '+47',
        countryCode: 'SJ'
    },
    {
        countryName: 'Swaziland',
        countryNameFr: 'Eswatini',
        phonePrefix: '+268',
        countryCode: 'SZ'
    },
    {
        countryName: 'Sweden',
        countryNameFr: 'Suède',
        phonePrefix: '+46',
        countryCode: 'SE'
    },
    {
        countryName: 'Switzerland',
        countryNameFr: 'Suisse',
        phonePrefix: '+41',
        countryCode: 'CH'
    },
    {
        countryName: 'Syrian Arab Republic',
        countryNameFr: 'Syrie',
        phonePrefix: '+963',
        countryCode: 'SY'
    },
    {
        countryName: 'Taiwan',
        countryNameFr: 'Taïwan',
        phonePrefix: '+886',
        countryCode: 'TW'
    },
    {
        countryName: 'Tajikistan',
        countryNameFr: 'Tadjikistan',
        phonePrefix: '+992',
        countryCode: 'TJ'
    },
    {
        countryName: 'Tanzania, United Republic of Tanzania',
        countryNameFr: 'Tanzanie',
        phonePrefix: '+255',
        countryCode: 'TZ'
    },
    {
        countryName: 'Thailand',
        countryNameFr: 'Thaïlande',
        phonePrefix: '+66',
        countryCode: 'TH'
    },
    {
        countryName: 'Timor-Leste',
        countryNameFr: 'Timor oriental',
        phonePrefix: '+670',
        countryCode: 'TL'
    },
    {
        countryName: 'Togo',
        countryNameFr: 'Togo',
        phonePrefix: '+228',
        countryCode: 'TG'
    },
    {
        countryName: 'Tokelau',
        countryNameFr: 'Tokelau',
        phonePrefix: '+690',
        countryCode: 'TK'
    },
    {
        countryName: 'Tonga',
        countryNameFr: 'Tonga',
        phonePrefix: '+676',
        countryCode: 'TO'
    },
    {
        countryName: 'Trinidad and Tobago',
        countryNameFr: 'Trinité-et-Tobago',
        phonePrefix: '+1868',
        countryCode: 'TT'
    },
    {
        countryName: 'Tunisia',
        countryNameFr: 'Tunisie',
        phonePrefix: '+216',
        countryCode: 'TN'
    },
    {
        countryName: 'Turkey',
        countryNameFr: 'Turquie',
        phonePrefix: '+90',
        countryCode: 'TR'
    },
    {
        countryName: 'Turkmenistan',
        countryNameFr: 'Turkménistan',
        phonePrefix: '+993',
        countryCode: 'TM'
    },
    {
        countryName: 'Turks and Caicos Islands',
        countryNameFr: 'Îles Turques-et-Caïques',
        phonePrefix: '+1649',
        countryCode: 'TC'
    },
    {
        countryName: 'Tuvalu',
        countryNameFr: 'Tuvalu',
        phonePrefix: '+688',
        countryCode: 'TV'
    },
    {
        countryName: 'Uganda',
        countryNameFr: 'Ouganda',
        phonePrefix: '+256',
        countryCode: 'UG'
    },
    {
        countryName: 'Ukraine',
        countryNameFr: 'Ukraine',
        phonePrefix: '+380',
        countryCode: 'UA'
    },
    {
        countryName: 'United Arab Emirates',
        countryNameFr: 'Émirats arabes unis',
        phonePrefix: '+971',
        countryCode: 'AE'
    },
    {
        countryName: 'United Kingdom',
        countryNameFr: 'Royaume-Uni',
        phonePrefix: '+44',
        countryCode: 'GB'
    },
    {
        countryName: 'United States',
        countryNameFr: 'États-Unis',
        phonePrefix: '+1',
        countryCode: 'US'
    },
    {
        countryName: 'Uruguay',
        countryNameFr: 'Uruguay',
        phonePrefix: '+598',
        countryCode: 'UY'
    },
    {
        countryName: 'Uzbekistan',
        countryNameFr: 'Ouzbékistan',
        phonePrefix: '+998',
        countryCode: 'UZ'
    },
    {
        countryName: 'Vanuatu',
        countryNameFr: 'Vanuatu',
        phonePrefix: '+678',
        countryCode: 'VU'
    },
    {
        countryName: 'Venezuela, Bolivarian Republic of Venezuela',
        countryNameFr: 'Venezuela',
        phonePrefix: '+58',
        countryCode: 'VE'
    },
    {
        countryName: 'Vietnam',
        countryNameFr: 'Vietnam',
        phonePrefix: '+84',
        countryCode: 'VN'
    },
    {
        countryName: 'Virgin Islands, British',
        countryNameFr: 'Îles Vierges britanniques',
        phonePrefix: '+1284',
        countryCode: 'VG'
    },
    {
        countryName: 'Virgin Islands, U.S.',
        countryNameFr: 'Îles Vierges des États-Unis',
        phonePrefix: '+1340',
        countryCode: 'VI'
    },
    {
        countryName: 'Wallis and Futuna',
        countryNameFr: 'Wallis-et-Futuna',
        phonePrefix: '+681',
        countryCode: 'WF'
    },
    {
        countryName: 'Yemen',
        countryNameFr: 'Yémen',
        phonePrefix: '+967',
        countryCode: 'YE'
    },
    {
        countryName: 'Zambia',
        countryNameFr: 'Zambie',
        phonePrefix: '+260',
        countryCode: 'ZM'
    },
    {
        countryName: 'Zimbabwe',
        countryNameFr: 'Zimbabwe',
        phonePrefix: '+263',
        countryCode: 'ZW'
    }
]

export default phonePrefixList
