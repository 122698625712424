<script setup lang="ts">
    import LogoMedia from '@/components/ui/medias/LogoMedia.vue'
    import { useI18n } from 'vue-i18n'

    const { t } = useI18n()
</script>

<template>
    <div class="footer-bottom">
        <div class="footer-bottom__logo-wrapper">
            <LogoMedia class="footer-bottom__logo" dark />
            <div v-safe-html="t('tagline')" class="footer-bottom__logo-tagline"></div>
        </div>
        <div class="footer-bottom__frenchfounders">{{ t('copyright', { year: new Date().getFullYear() }) }}</div>
        <div class="footer-bottom__app">
            <a
                href="https://play.google.com/store/apps/details?id=com.coobers.frenchfounders&hl=fr&gl=US"
                target="_blank"
                rel="noopener"
            >
                <img src="@/assets/images/homePage/footer/Google.svg" alt="Play Store" />
            </a>
            <a href="https://apps.apple.com/fr/app/frenchfounders/id1041061570" target="_blank" rel="noopener">
                <img src="@/assets/images/homePage/footer/Apple.svg" alt="Apple Store" />
            </a>
        </div>
    </div>
</template>

<style scoped lang="scss">
    @import '@/libs/sass/vars';
    .footer-bottom {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        width: 100%;
        gap: var(--scale-8);
        grid-template-columns: 1fr 1fr 1fr;
        padding-top: var(--scale-6);
        align-items: center;

        @media screen and (min-width: $breakpoints-md) {
            padding-top: var(--scale-20);
        }

        &__logo-wrapper {
            display: flex;
            gap: var(--scale-6);
            align-items: center;
        }

        &__logo {
            height: 2.25em;
        }

        &__logo-tagline {
            display: none;
            @media screen and (min-width: $breakpoints-sm) {
                display: initial;
                border-left: solid var(--scale-px) var(--theme-color-legacy);
                padding-left: var(--scale-6);
            }
        }

        &__frenchfounders {
            text-align: center;
            display: none;
            @media screen and (min-width: $breakpoints-sm) {
                display: initial;
            }
        }

        &__app {
            justify-content: end;
            display: flex;
            gap: var(--scale-4);
        }
    }
</style>

<i18n lang="json">
{
    "fr": {
        "tagline": "Le réseau business<br>francophone international",
        "copyright": "© {year} Frenchfounders"
    },
    "en": {
        "tagline": "The International Francophone<br>Business Network",
        "copyright": "© {year} Frenchfounders"
    }
}
</i18n>
