<script setup lang="ts">
    import { computed, ref, watch } from 'vue'
    import { useQuery, useQueryClient } from '@tanstack/vue-query'
    import { useAuthStore } from '@/libs/stores/auth.store'
    import { QueryName } from '@/vars/QueryAttr'
    import MemberApi from '@/libs/api/Member.api'
    import { SmartAction } from '@/types/response/members-api/SmartActions.type'
    import useWhatsNew from '@/libs/compositions/useWhatsNew'
    import SmartActionWhatsNew from '@/components/partials/network/smart-actions/action-whats-new/SmartActionWhatsNew.vue'
    import SmartActionLinkedin from '@/components/partials/network/smart-actions/action-linkedin/SmartActionLinkedin.vue'
    import SmartActionUpsellStakes from '@/components/partials/network/smart-actions/action-upsell-stakes/SmartActionUpsellStakes.vue'
    import { toMilliseconds } from '@/libs/helpers/numbers'
    import { useSegment } from '@/libs/vendors/segment/segment'

    withDefaults(
        defineProps<{
            theme?: 'compact' | 'normal'
        }>(),
        {
            theme: 'normal'
        }
    )

    const authStore = useAuthStore()
    const { analytics } = useSegment()
    const queryClient = useQueryClient()
    const smartActionComponents = ref<{ name: string; open: boolean }[]>([])

    const { data: smartActionsFetched } = useQuery({
        queryKey: [QueryName.SMART_ACTIONS],
        queryFn: () => MemberApi.getSmartActions(),
        enabled: () => authStore.isLoggedIn,
        refetchInterval: (query) =>
            query.state.data?.meta.refetchAfter
                ? toMilliseconds({ seconds: query.state.data.meta.refetchAfter })
                : undefined,
        gcTime: 0
    })

    const { hasNewProductUpdatesToShow, markProductUpdateAsRead } = useWhatsNew()

    const allSmartActions = computed<SmartAction[]>(() => {
        const smartActionsToShow: SmartAction[] = []

        if (smartActionsFetched.value) {
            smartActionsToShow.push(...smartActionsFetched.value.data)
        }

        if (hasNewProductUpdatesToShow.value) {
            smartActionsToShow.push({ key: 'whats_new' })
        }

        return smartActionsToShow
    })

    watch(
        smartActionsFetched,
        () => {
            analytics.track('community:onboarding:smart_actions:show')
        },
        { immediate: true }
    )

    function onRemove(action: SmartAction, isCompleted = false) {
        if (action.key === 'whats_new') {
            markProductUpdateAsRead()
        } else if (action.key === 'linkedin' && isCompleted) {
            // Enchaine sur la modal pour les enjeux d'upsell une fois la modal du profil linkedin terminée.
            const upsellStakeComponent = smartActionComponents.value.find(
                (component) => component.name === 'SmartActionUpsellStakes'
            )

            if (upsellStakeComponent) {
                upsellStakeComponent.open = true
            }
        }

        if (!smartActionsFetched.value) {
            return null
        }

        const newSmartActionsResult = { ...smartActionsFetched.value }

        newSmartActionsResult.data = newSmartActionsResult.data.filter(
            (actionFetched) => actionFetched.key !== action.key
        )

        queryClient.setQueryData([QueryName.SMART_ACTIONS], newSmartActionsResult)
    }

    function getComponentForAction(action: SmartAction) {
        switch (action.key) {
            case 'linkedin':
                return SmartActionLinkedin
            case 'whats_new':
                return SmartActionWhatsNew
            case 'stakes':
                return SmartActionUpsellStakes
            default:
                return null
        }
    }
</script>

<template>
    <div v-if="allSmartActions.length" class="smart-actions" :class="`smart-actions--${theme}`">
        <TransitionGroup name="actions">
            <Component
                :is="getComponentForAction(action)"
                v-for="(action, i) in allSmartActions"
                ref="smartActionComponents"
                :key="action.key"
                class="smart-actions__card"
                :action="action"
                :style="{ '--index': i }"
                @remove="onRemove"
            />
        </TransitionGroup>
    </div>
</template>

<style scoped lang="scss">
    .smart-actions {
        $smartActions: &;
        @apply min-h-20;

        &--compact {
            @apply relative;
        }

        &__card {
            #{$smartActions}--compact & {
                $depth: 0.07; // Increase to give depth
                $height: 0.5rem; // Increase height

                @apply absolute left-0 right-0 top-0;
                z-index: calc(20 - var(--index));
                top: calc(var(--index) * $height * -1);
                scale: calc(1 - (var(--index) * $depth));
                transition: all 300ms ease-in-out;
            }
        }
    }
    .card {
        $card: &;
        @apply flex items-center gap-2 px-2 py-4;

        &--blue {
            @apply bg-flagship-10;
        }

        &--green {
            @apply bg-go-10;
        }

        &__accent-color {
            #{$card}--blue & {
                @apply text-royale;
            }

            #{$card}--green & {
                @apply text-go;
            }
        }
    }

    .actions {
        &-enter-active,
        &-leave-active {
            transition: all 300ms ease-in-out;
        }
        &-enter-from,
        &-leave-to {
            opacity: 0;
            transform: translateY(50%);
        }
    }
</style>
