<script lang="ts" setup>
    import LinkWithChildren from '@/components/layouts/network/AsideLayout/partials/LinkWithChildren.vue'
    import usePublicHeaderNavigation from '@/libs/compositions/usePublicHeaderNavigation'

    defineEmits<{
        'click-link': []
    }>()

    const { mainLoggedNavigation } = usePublicHeaderNavigation()
</script>

<template>
    <nav class="nav flex flex-col">
        <ul role="list" class="flex flex-1 flex-col gap-y-7">
            <li>
                <ul v-for="section in mainLoggedNavigation" :key="section.title" role="list" class="mb-10">
                    <div v-if="section.title" class="text-xs font-medium leading-6 text-gray-400">
                        {{ section.title }}
                    </div>
                    <li v-for="link in section.links" :key="link.label">
                        <LinkWithChildren :link="link" @click="$emit('click-link')" />
                    </li>
                </ul>
            </li>
        </ul>
    </nav>
</template>

<style lang="scss" scoped>
    .nav {
        &__link {
            @apply flex cursor-pointer items-center gap-x-3 rounded-lg p-2 text-sm font-medium leading-6;
            transition: all 0.2s ease-in-out;

            &--active,
            &:hover {
                @apply bg-flagship-10;
            }
        }
    }
</style>
