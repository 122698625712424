<script setup lang="ts">
    import { computed, onMounted, ref, watch, nextTick } from 'vue'
    import SpaRouterLink from '@/components/ui/navigations/SpaRouterLink.vue'
    import MaterialIcon from '@/components/ui/icons/MaterialIcon.vue'
    import BaseLink from '@/components/ui/actions/BaseLink.vue'
    import useAnimation from '@/libs/compositions/useAnimation'
    import { Link } from '@/libs/compositions/usePublicHeaderNavigation'

    const props = defineProps<{
        link: Link
    }>()

    const emit = defineEmits<{
        click: []
    }>()

    const { open, close } = useAnimation()

    const areChildrenVisible = ref(false)
    const childrenEl = ref<HTMLDivElement>()

    const baseComponent = computed(() => {
        if (props.link.to) {
            return SpaRouterLink
        } else if (props.link.href) {
            return 'a'
        } else {
            return 'div'
        }
    })

    const componentProps = computed(() => {
        switch (baseComponent.value) {
            case SpaRouterLink:
                return { to: props.link.to }
            case 'a':
                return { href: props.link.href }
            default:
                return null
        }
    })

    const detectIfChildIsActive = computed(() => {
        return (props.link.children?.some((link) => link.active) && !areChildrenVisible.value) || false
    })

    const toggleChildren = () => {
        if (!props.link.children) {
            emit('click')
        }
        areChildrenVisible.value = !areChildrenVisible.value
    }

    watch(
        areChildrenVisible,
        () => {
            animateChildren()
        },
        { immediate: true }
    )

    function animateChildren(duration = 0.5) {
        if (!childrenEl.value) {
            return null
        }

        if (areChildrenVisible.value) {
            open(childrenEl.value, duration)
        } else {
            close(childrenEl.value, duration)
        }
    }

    onMounted(() => {
        // `nextTick` pour éviter de casser l'animation d'ouverture du menu sur mobile
        nextTick(() => {
            animateChildren(0)

            if (detectIfChildIsActive.value) {
                areChildrenVisible.value = true
            }
        })
    })
</script>

<template>
    <Component :is="baseComponent" v-bind="componentProps">
        <div
            class="menu-link"
            :class="{ 'menu-link--active': link.active || detectIfChildIsActive }"
            @click="toggleChildren"
        >
            <MaterialIcon class="title-4" :name="link.icon" />
            <span class="flex-1">
                {{ link.label }}
            </span>
            <MaterialIcon
                v-if="link.children"
                name="chevron_right"
                class="title-4 link__chevron ml-auto"
                :class="{ 'menu-link__chevron--open': areChildrenVisible }"
            />
        </div>
        <div ref="childrenEl" class="pt-1">
            <BaseLink
                v-for="sublink in link.children"
                :key="sublink.label"
                no-underline
                class="menu-link menu-link--child"
                :class="{ 'menu-link--active': sublink.active }"
                v-bind="{ href: sublink.href, to: sublink.to }"
                @click="$emit('click')"
            >
                {{ sublink.label }}
            </BaseLink>
        </div>
    </Component>
</template>

<style scoped lang="scss">
    $transition: all 0.2s ease-in-out;

    .menu-link {
        @apply flex cursor-pointer items-center gap-x-3 rounded-lg p-2  font-medium leading-6;
        transition: $transition;

        &--active,
        &:hover {
            @apply bg-flagship-10;
        }

        &--child {
            @apply p-0 font-normal;

            :deep(a) {
                @apply w-full p-2  pl-10;
            }
        }

        &__chevron {
            transition: $transition;

            &--open {
                transform: rotateZ(90deg);
            }
        }
    }
</style>
